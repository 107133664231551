.App-schedule-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    margin: auto;
    width: 100%;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(203 203 203 / 70%);
}

.App-schedule-popup-inner {
    background: #fff;
    width: 400px;
    max-width: 90vw;
    height: 400px;
    max-width: 90vh;
    padding: 20px;
}

.App-schedule-popup-buttons {
    margin: 20px 0;
}

.App-schedule-popup-buttons a {
    margin: 10px 10px;
    display: inline-block;
    text-decoration: none;
}

.App-schedule-popup-buttons a > div {
    padding: 20px 15px;
    border-radius: 20px;
}

.MuiDialog-root .MuiDialog-container {
    height: auto;
    padding: 50px 10px 0;
}
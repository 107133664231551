.App {
  text-align: center;
  background-color: #4cdbf9;
  max-width: 600px;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-info,
.App-info .MuiAccordion-root {
  background: #9beefb;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App-auth-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(203 203 203 / 70%);
}

[data-amplify-authenticator] {
  text-align: left;
}

span.rs__cell.rs__header {
  display: none;
}

[data-testid="grid"] > div:not(:first-child) > div:nth-child(3) {
  grid-template-columns: 0px repeat(1, 1fr);
}

[data-testid="grid"]>div:first-child>div:nth-child(3) {
  grid-template-columns: 50px repeat(1, 1fr);
}

[data-testid="grid"]>div>div:nth-child(2) {
  display: none;
}

[data-testid="grid"]>div:not(:first-child) .rs__cell.rs__time span {
  font-size: 0;
}

[data-testid="grid"]>div:first-child .rs__cell.rs__time {
  -webkit-writing-mode: horizontal-tb!important;
  writing-mode: horizontal-tb!important;
}

[data-testid="rs-wrapper"] > .MuiPaper-root {
  background-color: #0d387d;
  color: #fff;
  border-radius: 0;
}

[data-testid="rs-wrapper"]>.MuiPaper-root .MuiButtonBase-root {
  color: #fff;
}

.rs__cell {
  min-height: 61px;
}

.App-scheduler-userView .rs__cell {
  border-color: rgb(1 103 211) !important;
  border-width: 0 1px 2px 0 !important;
}

.rs__event__item {
  width: 100% !important;
}

.App-scheduler-userView .rs__event__item > div {
  box-shadow: none!important;
}

.App-scheduler-userView .rs__event__item {
  border-radius: 0 !important;
  background: rgb(1 103 211);
}

.App-scheduler-userView [data-testid="grid"]>div:not(:first-child)>div:nth-child(3) {
  border-color: rgb(1 103 211);
}

.App-scheduler-userView .rs__time {
  background: #9beefb!important;
}

.rs__table_loading {
  background: rgba(215, 215, 215, 0.9);
}